<script setup lang="ts">
import { toast, type ToastContainerOptions } from "vue3-toastify";

import type { DetailInvite, RewardNotice, PrizeDropsLevel } from "@/types";

const { locale, t } = useT();

useHead({
	titleTemplate: (pageTitle) => pageTitle || t("Funrize Social Casino: Play for Free"),
	htmlAttrs: {
		lang: locale
	}
});

const { open, close, isOpen } = useFunrizeModals();
const { handleOpenGame } = useOpenGame(open);
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const { isMobile } = useDevice();
const { data: appInitData } = useAppInitData();
const registrationType = useRegistrationType();
const { data: rewardsData, isRewardsAvailable } = useRewardsData({ server: true });
const isGuest = useIsGuest();
const loginGuard = useLoginGuard();
const { checkDailyOpen } = useWelcomeModal(open, close);
const router = useRouter();
const route = useRoute();
const { add } = useSockets();
const { logoutUser } = useLogout();
const { showGameComeBack } = useLastGameToast();
const { refresh: refreshScratchCards } = useScratchCard({ immediate: false });
const { refresh: refreshDepositStreak } = useFunrizeDepositStreakData();
const { refresh: refreshProfileData, updateRealityCheckInterval } = useProfileData();
const { refresh: refreshHomePageData } = useHomeData({ immediate: false });

useRankedLeagueSockets();
useRaceSockets({ showFinishedToast: true, t, openModal: open });
useSeasonsSocket();
useInitRegisterComplete();
usePresetPackageInit();
useCheckPromoOffer(open);
usePopupsInit();
useAppRefreshSocket(t);
useGoogleOneTap();
useUploadDocumentsToast("dark");
useExitOffer(open, close, isOpen);
useQuestsSocket();

const experimentIdCookie = useCookie("experiment_id", {
	path: "/",
	maxAge: 60 * 60 * 24 * 30,
	default: () => "3"
});
const experimentVariantCookie = useCookie<string | null>("experiment_variant", {
	path: "/",
	maxAge: 60 * 60 * 24 * 30,
	default: () => "nuxt"
});

const isEmailFirstContactAndNotConfirmed = computed(
	() => appInitData.value?.primaryContact === "email" && !appInitData.value?.emailConfirmed
);
const isShowEmailTopline = computed(
	() => !isGuest.value && isEmailFirstContactAndNotConfirmed.value && !isRewardsAvailable.value
);
const isGameModal = computed(() => isOpen("LazyOModalGame"));

const isShow = ref(false);
const { data: tournamentData } = useGetTournamentData();
const { data: amountPromotions } = useAsyncFetch({
	path: "/rest/page/promotions/amount/",
	method: "get",
	options: {
		cached: true,
		immediate: !isGuest.value,
		watch: [isGuest]
	}
});
const { setRewardComplete } = useProfileRewards();

const visitedRaces = computed(() =>
	appInitData.value?.gameMode === "SweepStakes"
		? appInitData.value?.visitedRaces?.SweepStakes
		: appInitData.value?.visitedRaces?.TournamentPoints
);
const isShowBadgeActiveRaces = computed(
	() => !!(tournamentData.value?.data?.isActive && visitedRaces.value !== tournamentData.value?.data?.id)
);
const isShowAccountDuplicateNotice = computed(() => !isGuest.value && appInitData.value?.accountStatus === "DUPLICATE");
const isSeasonEnabled = computed(() => appInitData.value?.season?.isEnabled);
const getAdditionalClasses = computed(() => ({
	"has-duplicate-notice": isShowAccountDuplicateNotice.value,
	"has-rewards": isRewardsAvailable.value,
	"has-email-notify": isShowEmailTopline.value
}));

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<div class="toast-invite-title">${titleText}</div>
			<div class="toast-invite-content">${t("You earned")}
				<span class="text-coimbatore bold">${detail.coins ? numberFormat(Number(detail.coins)) : ""}</span>
				<span>${detail.coins ? t("Coins") : ""}</span>
				<span class="text-cixi bold">${detail.entries ? numberFormat(Number(detail.entries)) : ""}</span>
				<span>${detail.entries ? t("Free Entries") : ""}.</span>
			</div>
		`,
		{
			toastId: detail.action,
			icon: h("i", { class: "icon-goal" }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: toast.POSITION.TOP_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

const toastPrizeDrops = (name: string, prize: string, prizeLevel: PrizeDropsLevel) => {
	toast.success(
		`
			<div class="title">${t("Shooting Stars")}</div>
			<div class="text"> ${t("User invader won Entries. Keep playing and get gifts.", { name, prize })}</div>
		`,
		{
			icon: h("img", {
				src: `/nuxt-img/prize-drops/stars/star-${PRIZE_DROPS_LEVEL[prizeLevel] || "bronze"}.png`,
				width: 40,
				height: 40
			}),
			dangerouslyHTMLString: true,
			containerId: "notification-prize-drops",
			theme: toast.THEME.DARK,
			position: toast.POSITION.TOP_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000,
			limit: 3,
			onClose: () => toast.clearAll("notification-prize-drops")
		} as ToastContainerOptions
	);
};

useAppBingo();
useBingoSocket();
usePrizeDropSocket({ t, open, showToast: toastPrizeDrops });

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		setTimeout(() => {
			showToast(t("Your friend get qualified!"), detail);
		}, 500);
	}
	if (detail.action === "player-qualified") {
		setTimeout(() => {
			showToast(t("You get qualified!"), detail);
		}, 500);
	}
});
add("contacts", (detail: EventData<RewardNotice>) => {
	if (detail.action === "confirmPhone") {
		setRewardComplete({
			type: "phone",
			completed: true,
			title: t("Phone Number Verified"),
			closeRewards: true
		});
	}

	if (detail.action === "confirmEmail") {
		setRewardComplete({ type: "email", completed: true, title: t("Email Verified"), closeRewards: true });
		appInitData.value!.emailConfirmed = true;
	}
});

add("profile", (detail: EventData<RewardNotice>) => {
	if (detail.action === "profileComplete") {
		setRewardComplete({
			type: "profile",
			completed: true,
			title: t("Profile Completed"),
			closeRewards: true
		});

		appInitData.value!.profileCompleted = true;
	}
});

watch(isGuest, async (value) => {
	if (!value) {
		await refreshProfileData();
		updateRealityCheckInterval();
	}
});

const headerRef = ref(null);
useResizeObserver(headerRef, (entries) => {
	const entry = entries[0];
	const { height } = entry.contentRect;
	// TODO temp approach for game popup offset
	document.body.style.setProperty("--top-height", `${height}px`);
});

onMounted(() => {
	const captcha = appInitData.value?.captcha;

	if (captcha && captcha.isEnabled && captcha.key) {
		useHead({
			script: [
				{
					src: captcha.url,
					id: captcha.key,
					async: true,
					defer: true
				}
			]
		});
	}
	updateRealityCheckInterval();

	// TODO update old variant/id only once
	if (+experimentIdCookie.value === 2) {
		experimentVariantCookie.value = null;
		experimentIdCookie.value = "3";
		setTimeout(() => (experimentVariantCookie.value = "nuxt"));
	}

	const { openNotify } = route.query;

	if (openNotify === "EMAIL_VERIFIED") {
		setRewardComplete({ type: "email", completed: true, title: t("Email Verified"), closeRewards: true });

		if (appInitData.value?.Registration?.registrationFunnel?.funnelType === "closed_funnel") {
			open("LazyOModalWelcome", { isEmailRegistration: false });

			dispatchGAEvent({
				event: "registration",
				location: "header",
				type: appInitData.value?.activeSocialAccounts?.[0] || registrationType.value,
				step: "success",
				playerId: appInitData.value?.login ?? ""
			});
		}
		dispatchFBEvents("trackCustom", "VerificationSuccess", {
			event_name: "ConfirmedEmail",
			event_time: Date.now(),
			action_source: "website",
			value: appInitData.value?.email
		});
	}

	loginGuard({
		success: () => {
			if (appInitData.value?.scratchCardLottery?.isActive) {
				checkDailyOpen({ storage: "showWelcomeScratchCards", modal: "LazyOModalScratchCardsWelcome" });
			}
		}
	});
});

useEvent(["SHOW_VERIFICATION_PHONE"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", { location: detail?.location });
});
useEvent(["COMPLETE_PROFILE_REWARD"], (event: Event) => {
	const { detail } = event as CustomEvent<{ rewardEntry: boolean; showRewardsModal: boolean; isOutsideCash: boolean }>;
	if (detail?.rewardEntry) {
		if (detail?.isOutsideCash) {
			window?.$cash?.$router?.push("/");
		}

		if (appInitData.value?.phone && appInitData.value.depositsCount! >= 1) {
			open("LazyOModalPhoneConfirmation", { confirmPhoneDeposit: !appInitData.value?.phone });
		}
	}
});
useEvent(["LOGOUT_AND_OPEN_AUTH_MODAL"], () => {
	if (window?.$cash?.$store?.getters?.["cash/isOpen"]) {
		window?.$cash?.$router?.push("/");
	}
	logoutUser();
	open("LazyOModalLogin");
});
useEvent(["nuxt:openGame"], (event: Event) => {
	const { detail } = event as CustomEvent;
	if (!isSweepStakes?.value) {
		handleSwitchGameMode();
	}
	handleOpenGame(detail?.slug);
});
useEvent(["nuxt:freeSpinRefresh"], () => {
	refreshHomePageData();
	const { refresh: refreshPromotionsPageData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate: false
		}
	});
	refreshPromotionsPageData();
});
useEvent(["nuxt:openVerificationBonus"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalVerificationBonus", { entries: detail });
});
useEvent(["nuxt:closeVerificationBonus"], () => {
	close("LazyOModalVerificationBonus");
});
useEvent(["nuxt:scratchCardsRefresh"], () => {
	refreshScratchCards();
});
useEvent(["nuxt:depositStreakRefresh"], () => {
	refreshDepositStreak();
});
useEvent(["nuxt:depositStreakHiW"], () => {
	open("LazyOModalDepositStreakHowItWorks");
});
useEvent(["nuxt:openDepositStreak"], () => {
	open("LazyOModalDepositStreak");
});
router.afterEach((_, from) => {
	const {
		query: { game }
	} = from;
	if (game && isMobile) {
		showGameComeBack();
	}
});
</script>

<template>
	<div class="layout">
		<ClientOnly>
			<LazyCash v-if="!isGuest" />
			<LazyOCookieControl />
		</ClientOnly>

		<section ref="headerRef" :class="['top-fixed', { 'over-modal': isGameModal }]">
			<LazyOAccountDuplicateNoticeTopline v-if="isShowAccountDuplicateNotice" />
			<LazyOProfileRewardsNoticeTopline v-else-if="!isGuest && isRewardsAvailable" :rewards="rewardsData.data" />
			<ClientOnly>
				<LazyOEmailNotifyTopline v-if="isShowEmailTopline" />
			</ClientOnly>
			<OHeader />
		</section>

		<main>
			<LazyOSidebar
				:showSidebar="isShow"
				:isShowBadgeActiveRaces="isShowBadgeActiveRaces"
				:amountPromotions="amountPromotions?.amount || 0"
				:additional-classes="getAdditionalClasses"
				@close-sidebar="isShow = false"
			/>
			<div class="page-content">
				<slot />
			</div>
		</main>
		<LazyOMobNavigation
			v-if="!isGuest"
			:isShowBadgeActiveRaces="isShowBadgeActiveRaces"
			:amountPromotions="amountPromotions?.amount || 0"
			:is-show-season="isSeasonEnabled"
			@open-sidebar="isShow = true"
		/>
		<ClientOnly>
			<LazyORewards v-if="!isGuest" />
		</ClientOnly>
		<LazyOFooter />
		<ASvgCollection />

		<ClientOnly>
			<LazySupportChat />
			<LazyOModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss">
#credential_picker_container {
	top: 63px !important;
}

.Toastify {
	&__toast {
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}

		&:has(.badge) {
			overflow: visible;
		}
	}

	&__toast-container {
		width: auto;
		@include media-breakpoint-down(lg) {
			left: 50%;
			transform: translateX(-50%);
		}

		#friend-qualified,
		#player-qualified,
		#finish-race {
			width: 320px;
			max-width: 100%;
			margin: gutter(1) auto;

			.Toastify__close-button > svg {
				height: 20px;
				width: 20px;
			}

			@include media-breakpoint-down(lg) {
				width: auto;
			}
		}

		#finish-race {
			margin-right: 0;
		}

		#account-id-copy {
			@include media-breakpoint-up(lg) {
				width: var(--toastify-toast-width);
				margin-left: auto;
			}
		}

		#promo-code-success {
			.Toastify__toast-icon {
				display: none;
			}
		}

		&#promo-cards {
			@include media-breakpoint-up(lg) {
				width: 640px;
				left: 50%;
				transform: translateX(-50%);
				right: auto;
			}
		}
	}

	.icon-goal {
		display: inline-flex;
		width: 64px;
		height: 64px;
		background-image: url(/assets/icons/64/goal.svg);
		background-position: center;
		background-repeat: no-repeat;
	}

	.icon-race {
		display: inline-flex;
		min-width: 70px;
		height: 50px;
		background-image: url("/nuxt-img/races/notify-icon.png");
		background-position: center;
		background-repeat: no-repeat;
	}

	.icon-checked {
		background-image: url(/assets/icons/20/checked.svg);
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		left: -13px;
		width: 26px;
		height: 20px;
		border-radius: gutter(0.5);
		color: var(--cannes);
		font-size: 12px;
		background-color: var(--cairo);
		z-index: 100;
	}

	#notification-password {
		.icon-checked {
			background-image: url(/assets/icons/20/green-success.svg);
		}
	}

	.icon-favorite {
		&.add {
			background-image: url(/assets/icons/20/favorite-add.svg);
		}

		&.remove {
			background-image: url(/assets/icons/20/favorite-remove.svg);
		}
	}
}

#notification-password {
	&.Toastify__toast-container {
		width: 328px;

		@include media-breakpoint-down(sm) {
			width: calc(100dvw - 32px);
		}
	}
}

#promo-code-success {
	&.Toastify__toast {
		width: 640px;

		@include media-breakpoint-down(lg) {
			width: calc(100dvw - 32px);
			margin-bottom: 67px;
		}
	}
}

#friend-qualified,
#player-qualified,
#finish-race {
	.toast-invite-title {
		margin-bottom: gutter(0.5);
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
	}

	.toast-invite-content {
		max-width: 160px;
	}

	.Toastify {
		&__close-button {
			display: block;
			opacity: 1;
		}

		&__toast-icon {
			width: 64px;
		}

		&__toast-body {
			white-space: normal;
		}
	}

	.bold {
		font-weight: 700;
	}
}

#finish-race {
	.Toastify {
		&__toast-icon {
			width: 70px;
		}
	}

	.finish-race-title {
		margin-bottom: gutter(0.5);
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
	}

	.finish-race-text {
		color: var(--comilla);
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
		text-decoration: underline;
	}
}

#toast-reward-container {
	width: auto;
	z-index: 99999;

	@include media-breakpoint-down(lg) {
		&.Toastify__toast-container--top-center {
			top: 80px;
		}
	}
}

#phone,
#email,
#profile {
	width: 344px;

	.Toastify {
		&__toast-body {
			white-space: nowrap;
		}

		&__toast-icon {
			width: 64px;
		}

		&__close-button {
			display: block;
			opacity: 1;

			> svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	.toast-reward-title {
		font-size: 16px;
		font-weight: 700;
		line-height: 22px;
		margin-bottom: gutter(1);
		color: var(--cannes);
	}

	.toast-reward-content {
		white-space: nowrap;
	}

	.icon-reward {
		display: inline-flex;
		width: 64px;
		height: 64px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}

#phone {
	.icon-reward {
		background-image: url(/assets/icons/85/confirm-phone.svg);
	}
}

#email {
	.icon-reward {
		background-image: url(/assets/icons/85/confirm-email.svg);
	}
}

#profile {
	.icon-reward {
		background-image: url(/assets/icons/85/profile-complete.svg);
	}
}

#email-confirm-container {
	width: 320px;
	max-width: 100%;

	.Toastify {
		&__toast {
			background: var(--camaguey);
		}

		&__toast-icon {
			display: none;
		}
	}
}

#notification-prize-drops {
	width: 328px;

	.Toastify {
		&__toast {
			border-radius: 8px;
			border-bottom: 1px solid var(--changshu);
			background: var(--curug);
			width: 100%;
			max-width: 328px;
			box-shadow: 0px 11px 18px 0px var(--chuncheon);
		}

		&__toast-body {
			align-items: flex-start;

			> div:last-child > div {
				display: flex;
				flex-direction: column;
			}

			.title {
				color: var(--cannes);
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
			}

			.text {
				color: var(--conakry);
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
			}
		}

		&__toast-icon {
			width: auto;
		}

		&__close-button {
			display: flex;
			width: 16px;
			height: 16px;
			opacity: 1;
			background: url(/assets/icons/16/close.svg) center/cover no-repeat;

			svg {
				display: none;
			}
		}
	}
}

#notification-season {
	@include media-breakpoint-down(lg) {
		max-width: 344px;
		left: 50%;
		transform: translate(-50%, 100px);
	}

	.Toastify {
		&__toast {
			padding: gutter(0.75) gutter(1.5);
			max-height: 128px;
			background: var(--galesong);
			margin-bottom: gutter(2);
		}

		&__close-button {
			display: flex;
			width: 16px;
			height: 16px;
			opacity: 1;
			background: url(/assets/icons/16/close.svg) center/cover no-repeat;

			svg {
				display: none;
			}
		}

		&__toast-icon {
			transform: translate(-12px, -6px);
			width: auto;
			margin-right: 0;

			img {
				width: auto;
				height: 128px;
			}
		}

		&__toast-body {
			align-items: flex-start;

			> div:last-child > div {
				display: flex;
				flex-direction: column;
				gap: gutter(1);
				margin-top: gutter(0.5);
				margin-left: gutter(-4);
			}

			.title {
				color: var(--coimbatore);
				text-shadow:
					0 4px 3px rgba(159, 98, 47, 0.6),
					0 1px 0 #a06116;
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: 0.5px;
			}

			.subtitle {
				font-size: 14px;
				line-height: 24px;
				display: flex;
				align-items: center;
				gap: 2px;

				b {
					display: flex;
					align-items: center;
					gap: gutter(0.5);
					margin-left: gutter(0.5);

					&::before {
						content: "";
						width: 20px;
						height: 20px;
						background: url("/nuxt-img/cards/entries.png") center/cover no-repeat;
					}
				}
			}
		}
	}
}

#notification-center-container,
#notification-center-container-email {
	width: 328px;
	top: 80px;

	@media (max-width: 480px) {
		left: 50%;
		transform: translateX(-50%);
	}

	.Toastify__toast {
		background-color: var(--choloma);
		margin-bottom: gutter(1.5);

		&-icon {
			width: 36px;
			margin-right: gutter(1);

			img {
				width: 36px;
				height: 36px;
			}
		}

		&-body {
			max-width: 280px;
			line-height: 0;

			.notification-title {
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
				margin: 0;
			}

			.notification-text {
				color: var(--conakry);
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}

	.Toastify__close-button {
		display: block;
		position: absolute;
		right: 8px;
		top: 8px;

		svg {
			width: 16px;
		}
	}
}

#cool-off-container {
	width: 328px;

	@media (max-width: 480px) {
		left: 50%;
		transform: translateX(-50%);
	}

	.Toastify__toast {
		&-body {
			align-items: flex-start;
			white-space: normal;
			line-height: 1.4;

			.subtitle {
				font-weight: 700;
				margin-bottom: gutter(1);
			}

			.support {
				color: var(--comilla);
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.Toastify__close-button {
		display: block;
		position: absolute;
		right: 8px;
		top: 8px;

		svg {
			width: 16px;
		}
	}
}

#upload-document-notification,
#upload-document-cash-notification {
	.Toastify__toast-icon {
		margin: 0;
		width: auto;

		.icon-close {
			visibility: hidden;
			width: 0;
		}
	}

	.Toastify__toast {
		min-width: 328px;
		width: 328px;
		top: 90px;
		height: auto;
		min-height: 56px;
		max-height: 68px;
		padding: gutter(1) gutter(1.5);
		background-color: var(--choloma);
		border-radius: 8px;
		border-bottom: 1px solid var(--changshu);
		box-shadow: 0px 11px 18px 0px var(--chuncheon);

		@media (max-width: 1024px) {
			width: 328px;
		}

		@media only screen and (max-width: 480px) {
			top: 110px;
		}

		.Toastify__toast-body {
			white-space: normal;
			margin: 0;
		}

		.Toastify__close-button {
			display: block;

			svg {
				height: 20px;
				width: 20px;
			}
		}

		.toast-content-container {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.toast-img-container {
			display: flex;
			align-items: center;
		}

		.toast-img {
			width: 40px;
			height: 40px;
		}

		.toast-text {
			padding: 0 0 0 gutter(1);
			line-break: auto;
			white-space: pre-line;
		}

		.toast-title {
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
		}

		.toast-message {
			color: var(--conakry);
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
		}
	}
}

#upload-document-cash-notification {
	.Toastify__toast {
		top: 0px;

		@media only screen and (max-width: 480px) {
			top: 20px;
		}
	}
}

.grecaptcha-badge {
	visibility: hidden;
}
</style>

<style lang="scss" scoped>
.layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.top-fixed {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2000;

	&.over-modal {
		@include media-breakpoint-up(lg) {
			z-index: 2002;
		}
	}
}
main {
	flex: 1;
	background: var(--cacem);
	margin-left: var(--o-sidebar-width);

	@include media-breakpoint-down(lg) {
		margin-left: 0;
	}
}
.page-content {
	display: block;
	width: 100%;
	padding: gutter(7);
	color: var(--conakry);
	position: relative;

	.prize-drops-page & {
		padding: 0;
	}

	@include media-breakpoint-down(lg) {
		padding: gutter(2.5) gutter(2);
		overflow: hidden;
	}
}
</style>
