import type { EventData, InitDataResponseData, StatusSystemType, PromoData } from "@/types";

type InitDataRankLeague = NonNullable<InitDataResponseData>["rankLeague"];
type InitDataProgressData = NonNullable<InitDataRankLeague>["progressData"];
type RankLeagueSocketData = {
	sectionType: StatusSystemType;
	progressData: InitDataProgressData;
	currentLevel: number;
	type: "wheel" | "promoOfferPreset" | "coinsEntries";
	promoOfferPreset?: PromoData;
	coins?: number;
};

const SKIP_LEVELS = [11, 21, 31, 41, 51, 61];

const useRankedLeagueSockets = () => {
	const { data } = useAppInitData();
	const { add, remove } = useSockets();
	const { open, isOpen, close } = useFunrizeModals();
	const { refresh: refreshLuckySpinData } = useGetLuckySpinData({ immediate: false });
	const { refresh: refreshPromotionsData } = usePromotionsPage({ immediate: false });
	const { exitFullscreen } = useFullscreenToggle();

	const reward = ref<RankLeagueSocketData>();

	add("rankLeague", (event: EventData<RankLeagueSocketData>) => {
		if (event.action === "sectionUp") {
			exitFullscreen();
			open("LazyOModalStatusSystem", { statusTag: event.data.sectionType });
		}
		if (event.action === "levelUp") {
			if (!isOpen("LazyOModalGame") || SKIP_LEVELS.includes(event.data.currentLevel)) {
				return;
			}

			exitFullscreen();
			setTimeout(() => {
				if (reward.value?.type) {
					open("LazyOModalStatusSystemLevelUp", { reward: reward.value });
				}
				dispatchGAEvent({
					event: "open_popup",
					quantity: event.data.currentLevel,
					form_name: "level_up",
					location: "reward"
				});

				reward.value = {} as RankLeagueSocketData;
			}, 1000);
		}
		if (event.action === "assignPrize") {
			if (isOpen("LazyOModalStatusSystemLevelUp")) {
				close("LazyOModalStatusSystemLevelUp");
			}
			const level = data.value?.rankLeague?.progressData?.section.level ?? 0;
			reward.value = event.data;

			if (event.data.type === "coinsEntries") {
				dispatchGAEvent({
					event: "open_popup",
					form_name: "coins_entries",
					quantity: level,
					location: "level_up"
				});
			}

			if (event.data.type === "promoOfferPreset") {
				refreshPromotionsData();
			}

			if (event.data.type === "wheel") {
				refreshLuckySpinData();
				dispatchGAEvent({
					event: "open_popup",
					form_name: "lucky_spin",
					quantity: level,
					location: "level_up"
				});
			}
		}
		if (event.action === "progressData" && data.value?.rankLeague && event.data.progressData) {
			data.value.rankLeague.progressData = { ...data.value.rankLeague.progressData, ...event.data.progressData };
		}
	});

	onUnmounted(() => {
		remove("rankLeague");
	});
};

export default useRankedLeagueSockets;
