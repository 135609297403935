import { apiClient } from "@netgame/openapi";

const useGoogleOneTap = () => {
	const launch = ref(false);
	const {
		public: { clientId }
	} = useRuntimeConfig();
	const isGuest = useIsGuest();
	const route = useRoute();
	const localPath = useLocalePath();

	const addScript = computed(() => launch.value && clientId);

	const setOneTapSingIn = async ({ credential }: { credential: string }) =>
		await apiClient({
			path: "/rest/google/one-tap/",
			method: "post",
			parameters: {
				body: { credential }
			},
			options: {
				onResponse: ({ response }) => {
					if (response._data.status) {
						dispatchGAEvent({
							event: "registration",
							location: "oneTap",
							step: "success",
							type: "google"
						});
						window.location.reload();
					}
				}
			}
		});

	/* eslint-disable indent */
	useHead(
		{
			script: () => [
				addScript.value
					? {
							src: "https://accounts.google.com/gsi/client",
							defer: true,
							onLoad: () => {
								try {
									window?.google?.accounts?.id?.initialize?.({
										client_id: clientId,
										callback: setOneTapSingIn,
										auto_select: false,
										cancel_on_tap_outside: true,
										context: "signin"
									});
									window?.google?.accounts?.id?.prompt?.();
								} catch (err) {
									console.warn(`[useGoogleOneTap]: ${err}`);
								}
							}
						}
					: {}
			]
		},
		{ mode: "client" }
	);

	watch(
		() => route.path,
		(value, oldValue) => {
			if (!isGuest.value) {
				return;
			}

			if (value === localPath("/") && oldValue !== localPath("/")) {
				window?.google?.accounts?.id?.prompt();
			}
		}
	);

	onMounted(() => {
		if (isGuest.value) {
			launch.value = true;
		}
	});
};

export default useGoogleOneTap;
