import { toast } from "vue3-toastify";

import type { EventData } from "@/types";

type SocketSeasonData = {
	completedLevelIndex?: number;
	levelProgressPercents?: number;
	entries?: number;
	coins?: number;
};

const showPrizeAssignNotify = ({
	t,
	prize,
	isMobile
}: {
	t: ReturnType<typeof useT>["t"];
	prize: number;
	isMobile: boolean;
}) => {
	const prizeImg = () => {
		if (prize > 4500) {
			return "large";
		}
		if (prize > 1500) {
			return "middle";
		}
		return "base";
	};
	toast.success(
		`
			<div class="title">${t("Congratulations!")}</div>
			<div class="subtitle">${t("You win")}<b>${prize}</b></div>
			<div class="text">${t("Keep playing and collect all Hawaii Adventure Season prizes!")}</div>
		`,
		{
			dangerouslyHTMLString: true,
			containerId: "notification-season",
			theme: toast.THEME.DARK,
			position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000,
			icon: h("img", {
				src: `/nuxt-img/seasons/funtastic/prize-${prizeImg()}.png`
			}),
			onClick: () => {
				dispatchGAEvent({
					event: "click_button",
					quantity: prize,
					form_name: "level_up_season_funtastic",
					location: "notification"
				});
			}
		}
	);
};

const useSeasonsSocket = () => {
	const { t } = useT();
	const { add, remove } = useSockets();
	const { open } = useFunrizeModals();
	const { isMobile } = useDevice();
	const { exitFullscreen } = useFullscreenToggle();
	const { data: appInitData } = useAppInitData();
	const activeWidgetSeason = useLocalStorage<null | boolean>("activeWidgetSeason", null);

	add("season", ({ action, data }: EventData<SocketSeasonData>) => {
		const { season } = appInitData.value || {};

		if (action === "progressUpdate" && season?.totalProgressPercents !== undefined) {
			season.totalProgressPercents = data?.levelProgressPercents ?? 0;
		}

		if (action === "prizeAssigned" && data?.entries) {
			exitFullscreen();
			showPrizeAssignNotify({ t, prize: data.entries * 100, isMobile });
			activeWidgetSeason.value = true;
		}

		if (action === "availableByDeposit") {
			if (season?.isAvailable !== undefined && season?.isActive !== undefined) {
				season.isAvailable = true;
			}
			open("LazyOModalSeasonTower");
		}
	});

	add("activity", ({ action }: EventData) => {
		if (action === "day_24h" && appInitData.value?.season?.isEnabled && !appInitData.value?.season?.isActive) {
			open("LazyOModalSeasonWelcome", { isSoundOff: true });
			localStorage.removeItem("showTowerModalInGamePopup");
		}
	});

	onUnmounted(() => {
		remove("season");
		remove("activity");
	});
};

export default useSeasonsSocket;
