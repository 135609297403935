import { toast } from "vue3-toastify";

import LastGameComponent from "../organizms/OLastGameToast.vue";

const useLastGameToast = () => {
	const { open } = useFunrizeModals();
	const { handleOpenGame } = useOpenGame(open);
	const { activeGameMode } = useSwitchMode();
	const showGameComeBack = () => {
		const lastGame = parseJSON(localStorage.getItem("lastGame") || "{}");

		if (!lastGame?.slug) {
			return;
		}

		const handleCloseToast = (event: MouseEvent, closeToast: Function) => {
			event.stopPropagation();
			localStorage.removeItem("lastGame");
			closeToast();
		};

		dispatchGAEvent({
			event: "tooltips",
			type: "return_to_game",
			location: "gameplay"
		});
		toast.success(LastGameComponent, {
			containerId: "last-game-container",
			icon: false,
			theme: toast.THEME.DARK,
			position: toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 4500,
			closeButton: ({ closeToast }) =>
				h("i", {
					class: "toast-icon icon-close",
					onClick: (event: MouseEvent) => handleCloseToast(event, closeToast)
				}),

			onClick: () => {
				handleOpenGame((lastGame?.slug ?? "") as string);
				dispatchGAEvent({
					event: "click_button",
					button_name: "play",
					location: "tooltips"
				});
				dispatchGAEvent({
					event: "gameplay",
					type: activeGameMode.value,
					status: "play"
				});
			}
		});
	};

	return {
		showGameComeBack
	};
};

export default useLastGameToast;
