const usePresetPackageInit = () => {
	const route = useRoute();
	const isGuest = useIsGuest();
	const guard = usePopupPresetPackageGuard();
	const { openDefault, openAuth, openAfterAuth, cookie } = usePresetPackageController();

	watch(isGuest, (value, oldValue) => {
		if (!value && oldValue) {
			if (route.query?.openModal === "app-banner-preset-package-modal") {
				openDefault();
			}

			if (route?.query?.action === "login") {
				openAfterAuth();
			}
		}
	});

	onMounted(() => {
		guard({
			success: () => {
				if (route?.query?.game || route?.hash?.includes("cash")) {
					return;
				}

				if (route?.query?.action === "login") {
					openAfterAuth();
				}

				const redirectToPackagePopup = route?.query?.redirectUrl === "bannerPresetPackage";
				const openModalPreset = route?.query?.openModal === "app-banner-preset-package-modal";

				if (!redirectToPackagePopup && !openModalPreset && !cookie.value) {
					openAfterAuth();
				}

				if (redirectToPackagePopup) {
					openAuth();
				}

				if (openModalPreset) {
					openDefault();
				}
			}
		});
	});
};

export default usePresetPackageInit;
