const useAppBingo = () => {
	const { data: appInit } = useAppInitData();
	const isGuest = useIsGuest();
	const loginGuard = useLoginGuard();
	const { open, close } = useFunrizeModals();
	const { checkDailyOpen } = useWelcomeModal(open, close);

	const handleShowPopups = () => {
		if (!appInit.value?.bingo?.isActive) {
			return;
		}
		checkDailyOpen({ storage: "showBingoWelcome", modal: "LazyOModalBingoWelcome" });
	};

	watch(isGuest, (val) => {
		if (!val) {
			handleShowPopups();
		}
	});

	onMounted(() => {
		loginGuard({
			success: () => {
				handleShowPopups();
			}
		});
	});
};

export default useAppBingo;
